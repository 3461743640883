/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'building-fill-lock': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1a1 1 0 011-1h10a1 1 0 011 1v7.764a3 3 0 00-4.989 2.497 2 2 0 00-.743.739H6.5a.5.5 0 00-.5.5V16H3a1 1 0 01-1-1zm2 1.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5m3 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5m3.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM4 5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5M7.5 5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm2.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5M4.5 8a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm2.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5"/><path pid="1" d="M9 13a1 1 0 011-1v-1a2 2 0 114 0v1a1 1 0 011 1v2a1 1 0 01-1 1h-4a1 1 0 01-1-1zm3-3a1 1 0 00-1 1v1h2v-1a1 1 0 00-1-1"/>',
    },
});
